import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const Typography = makeShortcode("Typography");
const BasicAudio = makeShortcode("BasicAudio");
const ButtonDialog = makeShortcode("ButtonDialog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "navigating-the-new-normal"
    }}>{`Navigating the ‘new normal’`}</h2>
    <p>{`Even as adults, adjusting to major life changes like those thrown at us during the pandemic is tough.
Remember, your teen is going through this while their brain is still developing `}{`—`}{` they are still learning to regulate their emotions, think rationally, problem-solve, and control their impulses.`}</p>
    <p>{`So how can you support them in navigating through this time?`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/5-covid-safe-activities.svg" alt="virtual-video-classroom" mdxType="SingleImage" />
    <Typography variant="h4" mdxType="Typography">Teens speak.</Typography>
    <p>{`What have been the biggest challenges for you during the pandemic? What would you like your parents to know about how they can support you? Hear what Ava, 16, has to say.`}</p>
    <BasicAudio src="/audios/Teens_speak_biggest_challenges_pandemic.mp3" mdxType="BasicAudio" />{' '}
    <ButtonDialog linkText="Read Transcript" title={<i>Transcript:</i>} mdxType="ButtonDialog">
  <b>
    What have been the biggest challenges for you during the pandemic? What would you like your parents to know about
    how they can support you?
  </b>
  <br />
  <br />
  <b>Ava, 16, says:</b>
  <br />
  <br />
  Where to start? Probably the worst part has been lockdowns and online learning. It’s not actually all about
  schoolwork, we lose so much more, like not seeing friends and teachers and just being at school together all day. Also
  not doing sports or any activities outside of school, I feel really unfit and like I only get the boring parts of
  school now, but not the fun parts.
  <br />
  <br />
  It’s also just so hard to do anything when you’re just stuck at home all day. My parents expect me to still get everything
  done all the time, like do well at school, help out around the house, stay active, talk to my grandparents on the phone…and
  sometimes, it’s just exhausting. I need a break sometimes you know? I’m trying my best to cope with everything, but sometimes
  it really gets to me.
  <br />
  <br />
  Whenever I talk to my parents about these things, they just tell me to try harder, or they try to fix the problem for
  me, or tell me off about the things I’m not doing instead of trying to understand WHY. I really wish they were more
  patient and understanding. And I DON’T always want their advice or solutions. Sometimes I just need them to listen and
  understand how I’m feeling. Like, just let me vent! And don’t just say “Oh well, there’s nothing you can do about
  it”…’cause that doesn’t help, even if it might be true!
    </ButtonDialog>
    <p>{`This section will cover:`}</p>
    <ul>
      <li parentName="ul">{`Helping your teen to cope with worries and uncertainties`}</li>
      <li parentName="ul">{`Supporting your teen as restrictions ease`}</li>
      <li parentName="ul">{`Helping your teen to stay connected`}</li>
      <li parentName="ul">{`Creating new routines`}</li>
      <li parentName="ul">{`Managing your expectations`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      